import { Countries, CountriesDTC, CountriesUS } from 'domains/Countries';
import { PaymentLabel } from './constants.d';

export const FIRST_PAGE = 0;
export const PAGES = 1;
export const TOTAL = 1;
export const MAX_PAGE_SIZE = 500;
export const MAX_PAGE_SIZE_PAGINATED = 100;

export const MAX_CHARACTERS = 60;

export default MAX_PAGE_SIZE;

export const UNACCEPTED_MINIMUM_ORDER_VALUE = -1;
export const EXPIRES_IN_DAYS_MAXIMUM_LENGTH = 2;
export const EXPIRES_IN_DAYS_MAXIMUM_VALUE = 99;

export const MAXIMUM_ORDER_DISABLED_VALUE = 9000000000000000;

export const VENDOR_APP_TIER_BASE_URL_V2 = '/api/vendor-management-app-tier/v2';
export const VENDOR_APP_TIER_BASE_URL = '/api/v1/vendor-management-app-tier';
export const DDC_APP_TIER_BASE_URL = '/api/v1/distribution-center-app-tier';
export const COMPANY_MANAGEMENT_BFF_BASE_URL = '/api/v1/company-management-bff';
export const ONE_STORE_MANAGEMENT_BFF_BASE_URL = '/api/one-store-management-bff';

export const COUNTRY_MATCHER = '^[A-Z]{2}$';

export const APP_ID = 'COMPANYMANAGEMENT';
export const STORE_UPDATES_ENTITY = 'STORE_UPDATES';
export const UPDATE_OPERATION = 'UPDATE';

export const VENDOR_ID_MOCK = '97324b2c-663c-4dc9-b04d-ac214162db00';
export const VENDOR_MOCK = { vendorId: '97324b2c-663c-4dc9-b04d-ac214162db00', serviceModel: '1P' };
export const USER_ID_MOCK = 'd53ab89e-a2f2-4e68-a06d-a813a35faf78';
export const GOVERNMENT_ID_BR_MOCK = '71.129.680/0001-96';
export const BEARER_TOKEN_MOCK =
  'Bearer eyJhbGciOiJIUzI1NiJ9.' +
  'eyJpc3MiOiJodHRwczovL2IyYmlhbWdidXNkZXYuYjJjbG9naW4uY29tLzljODlkZTllLWU4ZTItNDUxNi04ODdjLT' +
  'VmZWM0ODc4MzRlMi92Mi4wLyIsImV4cCI6MTYxNzYzNDkxMCwibmJmIjoxNjE3NjMxMzEwLCJhdWQiOiIxZmY4MmR ' +
  'mYi1mZTZkLTQ1NTgtYmIxYS01NjhiOTY2Y2M5NWQiLCJzdWIiOiJlMzBmYjI2OC1iNGY4LTRhOTktYWMzZS0zZDZlN' +
  'TIyNjc5MDAiLCJlbWFpbCI6Imd1c3Rhdm9mc0BjaWFuZHQuY29tIiwibmFtZSI6Ikd1c3Rhdm8gU2VuYSIsImdpdm ' +
  'VuX25hbWUiOiJHdXN0YXZvIiwiZmFtaWx5X25hbWUiOiJTZW5hIiwiY291bnRyeSI6IkRPIiwiZXh0ZW5zaW9uX3Ru' +
  'Y2FjY2VwdGVkZGF0ZXRpbWUiOjE2MTY0MTk2NDQsImNyZWF0ZWRPbiI6ImFkbWlucG9ydGFsIiwic2NvcGVzIjpbI ' +
  'kFkbWluUG9ydGFsVmVuZG9yLkNvbXBhbnlNYW5hZ2VtZW50R2xvYmFsLldyaXRlIiwiQWRtaW5Qb3J0YWxWZW5kb3I' +
  'uQ29tcGFueU1hbmFnZW1lbnQuV3JpdGUiXSwiYXBwIjoiYWRtaW5wb3J0YWwiLCJ0aWQiOiI5Yzg5ZGU5ZS1lOGUy ' +
  'LTQ1MTYtODg3Yy01ZmVjNDg3ODM0ZTIiLCJub25jZSI6ImRlZmF1bHROb25jZSIsImF6cCI6IjFmZjgyZGZiLWZlNm' +
  'QtNDU1OC1iYjFhLTU2OGI5NjZjYzk1ZCIsInZlciI6IjEuMCIsImlhdCI6MTYxNzYzMTMxMH0 ' +
  '.LhLPcSRZTtb75ddV7QpupCVrD7KAlTNal6BZG9VxIwo';

export const COUNTRY_TIERS_SUPPORT = [
  Countries.BR,
  Countries.AR,
  Countries.CO,
  Countries.CA,
  Countries.DO,
  Countries.EC,
  Countries.ES,
  Countries.HN,
  Countries.KR,
  Countries.MX,
  Countries.PA,
  Countries.PY,
  Countries.PE,
  Countries.SV,
  Countries.ZA,
];

export const SUPPORTED_COUNTRIES = Object.values(Countries);

export const DTC_SUPPORTED_COUNTRIES = Object.values(CountriesDTC);

export const US_SUPPORTED_COUNTRIES = Object.values(CountriesUS);

export const COUNTRY_POLYGON_COVERAGE_TYPE_SUPPORT = [Countries.CO, Countries.PE];

export const DEFAULT_LANGUAGE = 'en-US';

export enum VendorServiceModels {
  MODEL_3P_3L_SS = '3P-3L-SS',
  MODEL_3P_3L_SF = '3P-3L-SF',
}

export const OPTIMIZELY_PROJECT_NAME = 'admin-portal-vendor-management-mfe';

export const paymentLabels = (
  formatMessage: ({ id }: { id: string }) => string
): Array<PaymentLabel> => [
  {
    languages: ['en-US', 'en-ZA'],
    label: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_ENGLISH_LABEL' }),
    placeholder: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_ENGLISH_PLACEHOLDER' }),
  },
  {
    languages: [
      'es-AR',
      'es-CO',
      'es-EC',
      'es-HN',
      'es-DO',
      'es-MX',
      'es-PA',
      'es-PE',
      'es-PY',
      'es-SV',
    ],
    label: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_SPANISH_LABEL' }),
    placeholder: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_SPANISH_PLACEHOLDER' }),
  },
  {
    languages: ['zh-AR'],
    label: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_CHINESE_LABEL' }),
    placeholder: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_CHINESE_PLACEHOLDER' }),
  },
  {
    languages: ['pt-BR'],
    label: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_PORTUGUESE_LABEL' }),
    placeholder: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_PORTUGUESE_PLACEHOLDER' }),
  },
  {
    languages: ['fr-CA'],
    label: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_FRENCH_LABEL' }),
    placeholder: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_FRENCH_PLACEHOLDER' }),
  },
  {
    languages: ['ko-KR'],
    label: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_KOREAN_LABEL' }),
    placeholder: formatMessage({ id: 'PAYMENT_AND_ORDERS.INPUT_KOREAN_PLACEHOLDER' }),
  },
];

export const paymentLanguages = {
  Default: ['en-US'],
  AR: ['es-AR', 'zh-AR'],
  BR: ['pt-BR'],
  CA: ['fr-CA'],
  CO: ['es-CO'],
  EC: ['es-EC'],
  HN: ['es-HN'],
  KR: ['ko-KR'],
  DO: ['es-DO'],
  MX: ['es-MX'],
  PA: ['es-PA'],
  PE: ['es-PE'],
  PY: ['es-PY'],
  SV: ['es-SV'],
  ZA: ['en-ZA'],
};
